export default () => {
  document.querySelectorAll('.js-video-card-container').forEach(container => {
    const cardTopRight = container.querySelector('.js-video-card-top-right');
    const videoActions = container.querySelector('.js-video-card-video-actions');

    const displayVideoActions = () => {
      if (cardTopRight) {
        cardTopRight.style.display = 'none';
      }
      videoActions.style.display = 'block';
    }

    const hideVideoActions = () => {
      videoActions.style.display = 'none';
      if (cardTopRight) {
        cardTopRight.style.display = 'inline-flex';
      }
    }

    // 子要素のホバー時などにイベント発火してほしくないので、mouseenter,mouseleaveを使用している
    container.addEventListener('mouseenter', () => {
      displayVideoActions();
    });

    container.addEventListener('mouseleave', () => {
      hideVideoActions();
    });
  });
}
