import $ from 'jquery';

export default(url) => {
  const $videoForm = $('.js-direct-upload-video-file-form');
  const $fileInput = $('.js-video-file-input');
  const $submitButton = $('.js-submit-video');
  const $uploadProgress = $('.js-upload-progress');
  const $errorMessage = $('.js-error-message');

  const directUploadWithPresignedPost = (e) => {
    $submitButton.prop('disabled', true);
    $submitButton.addClass('is-loading');
    $uploadProgress.text('現在動画をアップロード準備中です。');

    const file = e.target.files[0];

    $.ajax({
      url: `${url}`,
      type: 'POST',
      dataType: 'json',
      data: {
        size: file.size,
        content_type: file.type,
        file_name: file.name
      },
      beforeSend: (xhr) => {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      }
    }).then((data) => {
      let name, fd = new FormData();
      for (name in data.fields) if (data.fields.hasOwnProperty(name)) {
        fd.append(name, data.fields[name]);
      }
      fd.append('file', file); // ファイルを添付

      const xhr = new XMLHttpRequest();
      xhr.open('post', data.url, true);
      xhr.onreadystatechange = (aEvt) => {
        if (xhr.readyState === 4) {
          if (xhr.status === 201) {
            const input = $('<input />', { type: 'hidden', name: 'direct_uploaded_video', value: data.upload_file_name });
            $videoForm.append(input);

            $submitButton.prop('disabled', false);
            $submitButton.removeClass('is-loading');
            $uploadProgress.text('動画のアップロード準備が完了しました。');
          } else {
            console.log('error');
          }
        }
      };
      xhr.send(fd);
    });
  };

  $fileInput.on('change', (e) => {
    directUploadWithPresignedPost(e);
  });

  $videoForm.on('submit', () => {
    // ActionDispatch::Http::UploadedFile の情報がparamsに入らないようにしている
    $fileInput.prop('disabled', true)
    $submitButton.prop('disabled', true);

    const form = $videoForm[0];
    const formData = new FormData(form);

    $.ajax({
      url: $videoForm.attr('action'),
      type: $videoForm.attr('method'),
      data: formData,
      processData: false,
      contentType: false
    }).then()
      .catch((res) => {
        $errorMessage.text(res.responseText);
        $submitButton.prop('disabled', false);
        $submitButton.removeClass('is-loading');
        $fileInput.prop('disabled', false);
        $('html, body').animate({ scrollTop: 0 });
      });

    return false;
  })
}
