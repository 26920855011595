import $ from "jquery";

export default (videoId)=> {
  $(function() {
    $('input[name="video_order[use_ids][]"], #video_order_start_date, #video_order_end_date').change(function() {
      const checked_use_ids=[];
      $("[name='video_order[use_ids][]']:checked").each(function(){
        checked_use_ids.push(this.value);
      });

      $.ajax({
        url: `/videos/${videoId}/dynamic_change_video_fee`,
        type: 'GET',
        dataType: 'json',
        data: {
          use_ids: checked_use_ids,
          start_date: $('#video_order_start_date').val(),
          end_date: $('#video_order_end_date').val()
        }
      }).then((res) => {
        const videoOrder = res.video_order;
        const $originalTotalPrice = $('.js-video-order-original-total-price');

        $('.js-video-order-price').text(`¥ ${videoOrder.price.toLocaleString()}`);
        $('.js-video-order-discount-message').text(`${videoOrder.discount_message}`);
        if (videoOrder.discount_message) {
          $originalTotalPrice.text(`¥ ${videoOrder.original_total_price.toLocaleString()}`);
        } else {
          $originalTotalPrice.text('');
        }
      });
    });
  });
}
