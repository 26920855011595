import $ from "jquery";

// 動画をuploadしたらプレビューを表示
export default function() {
  $('#video_video_file').on('change', (event) => {
    const $file = event.currentTarget.files[0];
    const $videoEl = $(".js-player");
    $videoEl.css('display', 'block');
    $videoEl.attr('src', URL.createObjectURL($file));
  });
}
