import $ from "jquery";

// cocoon-js
// 動画素材の「シーン追加」
export default () => {
  $('#add_scene').on('click', function () {
    $("#scene_fields").on("cocoon:after-insert", function () {
      const time = Math.floor(document.querySelector('.js-player').currentTime);
      $('#scene_fields .nested-fields:last .starttime').val(time);
    });
  });
}
