import $ from "jquery";

// 動画コンテンツのフォーム・動画素材の選択による使用用途の制限
export default function() {
  $(function() {
    const ajaxFetchSelectableUses = function() {
      const activateCheckbox = function($checkbox) {
        $checkbox.prop('disabled', false);
        $checkbox.parent().removeClass('has-text-grey-light').css('cursor', 'grab');
      };

      const notActivateCheckbox = function($checkbox) {
        $checkbox.prop('disabled', true).prop('checked', false);
        $checkbox.parent().addClass('has-text-grey-light').css('cursor', 'not-allowed');
      };

      const $form = $('.js-video-content-form');
      const $videoSelects = $form.find('.js-video-select');
      let videoIds = [];

      $videoSelects.each(function() {
        videoIds.push($(this).val());
      });

      // 動画素材のセレクトでpromptを選択された場合に空文字列が入るので除外
      // 空文字列が入るとサーバー側で「動画が選択された状態」として判定されてしまうので対応
      // @see https://qiita.com/akameco/items/1636e0448e81e17e3646
      videoIds = videoIds.filter(Boolean);

      $.ajax({
        url: '/creator/video_contents/fetch_selectable_uses',
        type: 'GET',
        dataType: 'json',
        data: {
          video_ids: videoIds,
        }
      }).then(function(res) {
        const useIds = res.use_ids;
        const $useCheckboxes = $form.find('.js-use-checkbox');

        $useCheckboxes.each(function() {
          if (useIds.length === 0) {
            // 動画素材を選択した時に用途を1つもチェックできない条件になった場合、全ての用途をチェックできないようにする
            notActivateCheckbox($(this));
          } else if (useIds.indexOf(Number($(this).val())) === -1) {
            // 動画素材を選択した時にチェックできなくなった用途があればdisabledを付ける
            notActivateCheckbox($(this));
          } else if (useIds.indexOf(Number($(this).val())) !== -1) {
            // 動画素材を選択した時にチェックできるようになった用途にdisabledが付いていたら外す
            activateCheckbox($(this));
          }
        });
      });
    };

    // 動画素材が選択された時
    $(document).on('change', '.js-video-select', function() {
      ajaxFetchSelectableUses();
    });

    // 動画素材が削除された時
    $('#video-content-material-fields').on('cocoon:after-remove', function(e, removedItem) {
      // 保存済みの動作素材をcocoonで削除した後はdisplay: none;になるだけでDOMが残るためDOMを削除している
      let removedVideo = removedItem.find('.js-video-select');
      removedVideo.remove();
      ajaxFetchSelectableUses();
    });

    // ページがロードされた時に実行
    ajaxFetchSelectableUses();
  });
}
