// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("channels")
require('jquery')
import $ from "jquery";
import 'cocoon-js';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

// chartkick
require("chartkick")
require("chart.js")

// fortawesome
import '@fortawesome/fontawesome-free/js/all'

$(document).on("turbolinks:load", function() {
  $(".navbar-burger").click(function() {
    $(".navbar-burger").toggleClass("is-active");
    $(".navbar-menu").toggleClass("is-active");
  });
});

// style
require("./stylesheets/application.scss");

// select2
require("select2/dist/css/select2");
import Select2 from "select2";
$.fn.select2.defaults.set("width", "100%");
$(document).on("turbolinks:load", function() {
  $(".js-searchable").select2()

  $('#video-content-material-fields').on('cocoon:after-insert', function() {
    $('.js-searchable').select2();
  });
});

// jpostal
require('jquery-jpostal-ja');

$(document).on("turbolinks:load", function() {
  $(window).ready(function() {
    $('#zip').jpostal({
      postcode : ['#zip'],
      address : { '#address'  : '%3%4%5%6%7'}
    });
  });
});

/*-----------
 Modal
-----------*/
document.addEventListener('turbolinks:load', () => {
// Save DOM Elements
  const modalTriggers = document.querySelectorAll(".modal-trigger");
  const modalCloses = document.querySelectorAll(".modal-close, .modal-background, .modal-content a");

// Set click event handlers on each of the triggers.
  Array.from(modalTriggers).forEach((trigger) => {
    trigger.addEventListener("click", function (e) {
      e.preventDefault();
      const modalName = trigger.dataset.modal;
      const modal = document.querySelector(`.${modalName}`);
      modal.classList.add("is-active");
      document.querySelector('html').classList.add("is-clipped");
    });
  });

// Set click event handlers on the different close modal triggers.
  Array.from(modalCloses).forEach((closeSymbol) => {
    closeSymbol.addEventListener("click", function () {
      this.closest(".modal").classList.remove("is-active");
      document.querySelector('html').classList.remove("is-clipped");
    });
  });
});

// 一番上の名前空間
window.DD = window.DD || {};

window.DD.Welcome = window.DD.Welcome || {};
// root path
import welcomeIndex from './javascripts/views/welcome/index'
window.DD.Welcome.Index = window.DD.Welcome.Index || {};
window.DD.Welcome.Index = welcomeIndex;

window.DD.Search = window.DD.Search || {};
import SearchIndex from './javascripts/views/search/index'
window.DD.Search.Index = window.DD.Search.Index || {};
window.DD.Search.Index = SearchIndex;

window.DD.Categories = window.DD.Categories || {};
import CategoriesShow from './javascripts/views/categories/show'
window.DD.Categories.Show = window.DD.Categories.Show || {};
window.DD.Categories.Show = CategoriesShow;

window.DD.FreeVideoCampaigns = window.DD.FreeVideoCampaigns || {};
import FreeVideoCampaignsShow from './javascripts/views/free_video_campaigns/show';
window.DD.FreeVideoCampaigns.Show = window.DD.FreeVideoCampaigns.Show || {};
window.DD.FreeVideoCampaigns.Show = FreeVideoCampaignsShow;

window.DD.Providers = window.DD.Providers || {};
import ProvidersShow from './javascripts/views/providers/show';
window.DD.Providers.Show = window.DD.Providers.Show || {};
window.DD.Providers.Show = ProvidersShow;

// admin
window.DD.Admin = window.DD.Admin || {};

// Admin管理ページ共通で呼び出す処理
window.DD.Admin.Dashboard = window.DD.Admin.Dashboard || {};
import activateSidebarLink from './javascripts/shared/activateSidebarLink';
window.DD.Admin.Dashboard = activateSidebarLink;

// admin/videos
window.DD.Admin.Videos = window.DD.Admin.Videos || {};
// admin/videos/new
import AdminVideosNew from './javascripts/views/admin/videos/new'
window.DD.Admin.Videos.New = window.DD.Admin.Videos.New || {};
window.DD.Admin.Videos.New = AdminVideosNew;
// admin/videos/edit
import AdminVideosEdit from './javascripts/views/admin/videos/edit'
window.DD.Admin.Videos.Edit = window.DD.Admin.Videos.Edit || {};
window.DD.Admin.Videos.Edit = AdminVideosEdit;
// admin/videos/show
import AdminVideosShow from './javascripts/views/admin/videos/show'
window.DD.Admin.Videos.Show = window.DD.Admin.Videos.Show || {};
window.DD.Admin.Videos.Show = AdminVideosShow;

// admin/videos/free_videos
window.DD.Admin.Videos.FreeVideos = window.DD.Admin.Videos.FreeVideos || {};
// admin/videos/free_videos/show
import AdminFreeVideosShow from './javascripts/views/admin/videos/free_videos/show'
window.DD.Admin.Videos.FreeVideos.Show = window.DD.Admin.Videos.FreeVideos.Show || {};
window.DD.Admin.Videos.FreeVideos.Show = AdminFreeVideosShow;

// admin/free_video_campaigns
window.DD.Admin.FreeVideoCampaigns = window.DD.Admin.FreeVideoCampaigns || {};
import AdminFreeVideoCampaignNew from './javascripts/views/admin/free_video_campaigns/new'
window.DD.Admin.FreeVideoCampaigns.New = window.DD.Admin.FreeVideoCampaigns.New || {};
window.DD.Admin.FreeVideoCampaigns.New = AdminFreeVideoCampaignNew;

import AdminFreeVideoCampaignEdit from './javascripts/views/admin/free_video_campaigns/edit'
window.DD.Admin.FreeVideoCampaigns.Edit = window.DD.Admin.FreeVideoCampaigns.Edit || {};
window.DD.Admin.FreeVideoCampaigns.Edit = AdminFreeVideoCampaignEdit;

// admin/organizations
window.DD.Admin.Organizations = window.DD.Admin.Organizations || {};
// admin/organizations/index
import AdminOrganizationsIndex from "./javascripts/views/admin/organizations/index";
window.DD.Admin.Organizations.Index = window.DD.Admin.Organizations.Index || {};
window.DD.Admin.Organizations.Index = AdminOrganizationsIndex;

// provider
window.DD.Provider = window.DD.Provider || {};
// provider/videos
window.DD.Provider.Videos = window.DD.Provider.Videos || {};
// provider/videos/new
import ProviderVideosNew from './javascripts/views/provider/videos/new'
window.DD.Provider.Videos.New = window.DD.Provider.Videos.New || {};
window.DD.Provider.Videos.New = ProviderVideosNew;
// provider/videos/edit
import ProviderVideosEdit from './javascripts/views/provider/videos/edit'
window.DD.Provider.Videos.Edit = window.DD.Provider.Videos.Edit || {};
window.DD.Provider.Videos.Edit = ProviderVideosEdit;
// provider/videos/show
import ProviderVideosShow from './javascripts/views/provider/videos/show'
window.DD.Provider.Videos.Show = window.DD.Provider.Videos.Show || {};
window.DD.Provider.Videos.Show = ProviderVideosShow;
// provider/videos/index
import ProviderVideosIndex from './javascripts/views/provider/videos/index'
window.DD.Provider.Videos.Index = window.DD.Provider.Videos.Index || {};
window.DD.Provider.Videos.Index = ProviderVideosIndex;

// provider/videos/free_videos
window.DD.Provider.Videos.FreeVideos = window.DD.Provider.Videos.FreeVideos || {};
// provider/videos/free_videos/show
import ProviderFreeVideosShow from './javascripts/views/provider/videos/free_videos/show'
window.DD.Provider.Videos.FreeVideos.Show = window.DD.Provider.Videos.FreeVideos.Show || {};
window.DD.Provider.Videos.FreeVideos.Show = ProviderFreeVideosShow;


// creator
window.DD.Creator = window.DD.Creator || {};
// creator/video_contents
window.DD.Creator.VideoContents = window.DD.Creator.VideoContents || {};
// creator/video_contents/new
import CreatorVideoContentsNew from './javascripts/views/creator/video_contents/new'
window.DD.Creator.VideoContents.New = window.DD.Creator.VideoContents.New || {};
window.DD.Creator.VideoContents.New = CreatorVideoContentsNew;
// creator/video_contents/edit
import CreatorVideoContentsEdit from './javascripts/views/creator/video_contents/edit'
window.DD.Creator.VideoContents.Edit = window.DD.Creator.VideoContents.Edit || {};
window.DD.Creator.VideoContents.Edit = CreatorVideoContentsEdit;
// creator/video_contents/show
import CreatorVideoContentsShow from './javascripts/views/creator/video_contents/show'
window.DD.Creator.VideoContents.Show = window.DD.Creator.VideoContents.Show || {};
window.DD.Creator.VideoContents.Show = CreatorVideoContentsShow;

// videos
window.DD.Videos = window.DD.Videos || {};
// videos/show
import VideosShow from './javascripts/views/videos/show'
window.DD.Videos.Show = window.DD.Videos.Show || {};
window.DD.Videos.Show = VideosShow;

// video_contents
window.DD.VideoContents = window.DD.VideoContents || {};
// video_contents/show
import VideoContentsShow from './javascripts/views/video_contents/show'
window.DD.VideoContents.Show = window.DD.VideoContents.Show || {};
window.DD.VideoContents.Show = VideoContentsShow;

// customer
window.DD.Customer = window.DD.Customer || {};

// Customer管理ページ共通で呼び出す処理
window.DD.Customer.Dashboard = window.DD.Customer.Dashboard || {};
window.DD.Customer.Dashboard = activateSidebarLink;

// customer/ticket_deals
window.DD.Customer.TicketDeals = window.DD.Customer.TicketDeals || {};

// customer/ticket_deals/new
import TicketDealsNew from './javascripts/views/customer/ticket_deals/new'
window.DD.Customer.TicketDeals.New = window.DD.Customer.TicketDeals.New || {};
window.DD.Customer.TicketDeals.New = TicketDealsNew;

// customer/purchases
window.DD.Customer.Purchases = window.DD.Customer.Purchases || {};

// customer/purchases/new
import PurchasesNew from './javascripts/views/customer/purchases/new'
window.DD.Customer.Purchases.New = window.DD.Customer.Purchases.New || {};
window.DD.Customer.Purchases.New = PurchasesNew;
