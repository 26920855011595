import Splide from '@splidejs/splide';

export default () => {
    document.addEventListener('turbolinks:load', () => {
        const cardSliders = document.querySelectorAll('.js-card-slider');
        Array.from(cardSliders).forEach((cardSlider) => {
            new Splide(cardSlider, {
                rewind: true,
                perPage: 4,
                autoHeight: true,
                trimSpace: true,
                breakpoints: {
                    768: {
                        perPage: 1,
                        pagination: false,
                        arrows: false,
                        padding: {
                            left: 0,
                            right: '6rem',
                        },
                    },
                },
            }).mount();
        });
    });
}
