import $ from "jquery";

export default () => {
  const currentPath = location.pathname;

  $('ul.js-sidebar-list li a').each((i,v) => {
    const href = $(v).attr('href')
    if (currentPath === href) {
      $(v).addClass('is-active');
    }
  })
}
