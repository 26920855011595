export default () => {
  $('.js-check-all-videos').on('click', () => {
    $('.js-video-checkbox').prop('checked', true);
  });

  $('.js-uncheck-all-videos').on('click', () => {
    $('.js-video-checkbox').prop('checked', false);
  });
}

