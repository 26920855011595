
export default () => {
    document.addEventListener('turbolinks:load', () => {
        const districts = ['hokkaido', 'tohoku', 'kanto', 'chubu', 'kinki', 'chugoku', 'shikoku', 'kyushu', 'okinawa' ]
        districts.forEach((district) => {
            const mapTargets = document.querySelectorAll(`.${district}`)

            mapTargets.forEach((mapTarget) => {
                mapTarget.addEventListener('mouseenter', () => {
                    mapTargets.forEach((target) => {
                        target.classList.add('fill-color')
                    })
                })
                mapTarget.addEventListener('mouseleave', () => {
                    mapTargets.forEach((target) => {
                        target.classList.remove('fill-color')
                    })
                })
            })
        })
    })
}