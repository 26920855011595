
import $ from "jquery";

export default () => {
  $(() => {
    let $inputs = $('.js-ticket-plans').find('.js-ticket-plan-input');

    const displayPrice = () => {
      $inputs = $('.js-ticket-plans').find('.js-ticket-plan-input');

      // プランのidと個数のセットを作る
      const plan2Volumes = [];

      $inputs.each(function() {
        const ticketPlanId = $(this).parent().find('.js-ticket-plan-id').val();
        const volume = $(this).val();
        plan2Volumes.push({[ticketPlanId]: volume})
      });

      $.ajax({
        url: '/customer/ticket_deals/fetch_selected_ticket_plans',
        type: 'GET',
        dataType: 'json',
        data: {
          plan2volumes: plan2Volumes
        }
      }).then((res) => {
        $('.js-ticket-deal-amount').text(`${res.ticket_deal.amount}枚`);
        $('.js-ticket-deal-subtotal').text(`¥${res.ticket_deal.price.toLocaleString()}`);
        $('.js-ticket-deal-tax-price').text(`¥${res.ticket_deal.tax_price.toLocaleString()}`);
        $('.js-ticket-deal-tax-included-price').text(`¥${res.ticket_deal.tax_included_price.toLocaleString()}`);
      })
    };

    // バリデーションエラー後のレンダリングに対応するために設定
    // ただし、ページ遷移時にはチケットプランのinputが1つも表示されていないためdisplayPriceを実行させないようにしている
    if ($inputs.length !== 0) {
      displayPrice();
    }

    $(document).on('keyup blur change', '.js-ticket-plan-input', () => {
      displayPrice();
    });

    $('.js-ticket-plans').on('cocoon:after-insert', () => {
      displayPrice();
    });

    $('.js-ticket-plans').on('cocoon:after-remove', () => {
      $inputs = $('.js-ticket-plans').find('.js-ticket-plan-input');

      if ($inputs.length > 0) {
        displayPrice();
      } else {
        $('.js-ticket-deal-amount').text('選択されていません。');
        $('.js-ticket-deal-subtotal').text('');
        $('.js-ticket-deal-tax-price').text('');
        $('.js-ticket-deal-tax-included-price').text('');
      }
    });

    // 各プランにつき「追加」ボタンを1つまでしか表示させない
    $('.js-add-ticket-plan-input').on('click', (event) => {
      $(event.currentTarget).parent().hide();
    });

    // プランの数量入力フィールドが削除されたとき、「追加」ボタンを表示
    $(document).on('click', '.js-remove-ticket-plan-input', (event) => {
      $(event.currentTarget).parent().parent().parent().find('.js-add-ticket-plan-input-wrapper').show();
    });
  });
}
