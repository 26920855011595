export default () => {
  const useCheckboxes = document.querySelectorAll(
    ".js-use-checkboxes-wrapper input"
  );
  const periodCheckbox = document.querySelector(".js-period-checkbox");

  if (periodCheckbox.checked) {
    useCheckboxes.forEach((checkbox) => {
      checkbox.disabled = true;
    });
  }

  periodCheckbox.onclick = (event) => {
    if (event.target.checked) {
      useCheckboxes.forEach((checkbox) => {
        checkbox.disabled = true;
      });
    } else {
      useCheckboxes.forEach((checkbox) => {
        checkbox.disabled = false;
      });
    }
  };
};
