import selectDate from '../../../shared/selectDate'
import controlVideoCheckboxes from '../../../shared/free_video_campaigns/controlVideoCheckboxes'

export default function() {
  const tomorrow = new Date().fp_incr(1);
  const config =  { minDate: tomorrow };
  selectDate(config);

  controlVideoCheckboxes();
};
