import videoPlayer from "../../shared/videoPlayer";
import selectDate from "../../shared/selectDate";
import dynamicChangePrice from "../../shared/videos/dynamicChangePrice";
import sceneMove from "../../shared/videos/sceneMove";
import videoCardHover from "../../shared/videoCardHover";

export default (videoId, is_free_without_campaign) => {
  videoPlayer();

  let config = { minDate: "today" };

  // 0円の動画の使用可能期間は90日までと制限する処理
  if (is_free_without_campaign === "true") {
    const additionalConfig = { maxDate: new Date().fp_incr(90) };
    config = { ...config, ...additionalConfig };
  }

  selectDate(config);
  dynamicChangePrice(videoId);
  sceneMove();
  videoCardHover();
};
