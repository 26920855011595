import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin'

export default (additionalConfig) => {

  const defaultConfig =  {
    dateFormat: 'Y/m/d',
    "plugins": [new rangePlugin({ input: '.js-datepicker-end-date'})]
  }

  const config = { ...defaultConfig, ...additionalConfig };
  flatpickr(".js-datepicker", config);
}

