import selectPrefectureAndCity from '../../../shared/videos/selectPrefectureAndCity';
import directUploadVideoFile from '../../../shared/directUploadVideoFile';
import disableUsesForUnlimited from '../../../shared/videos/disableUsesForUnlimited';
import videoPreview from '../../../shared/videos/videoPreview';
import displayLoading from '../../../shared/videos/displayLoading';
import addScene from '../../../shared/videos/addScene';
import videoPlayer from '../../../shared/videoPlayer';

export default function(namespace) {
  selectPrefectureAndCity(namespace);
  directUploadVideoFile(`/${namespace}/videos/execute_presigned_post`);
  disableUsesForUnlimited();
  videoPreview();
  displayLoading();
  addScene();
  videoPlayer();
}
