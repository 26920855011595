import $ from "jquery";

// 動画素材の「県名・市区町村名の選択」
// AjaxのURLがadminとproviderで違うので、引数に名前空間の文字列を渡すようにしている
export default function(namespace) {
  $(function() {
    const ajaxFetchSelectableCities = function($prefectureSelect) {
      const findCitySelect = function($prefectureSelect) {
        return $prefectureSelect.parent().parent().find('.js-city-select');
      };

      const emptyCitySelect = function($citySelect) {
        $citySelect.children().remove();
        const prompt = $('<option>').val(null).text('市区町村名を選択してください');
        $citySelect.append(prompt);
      };

      const prefectureId = $prefectureSelect.val();

      // newのロード時 or 県のselectのpromptが選択された時 に対応
      if(prefectureId.length === 0) {
        const $citySelect = findCitySelect($prefectureSelect);
        emptyCitySelect($citySelect);
        $citySelect.prop('disabled', true);
        return
      }

      $.ajax({
        url: `/${namespace}/videos/fetch_selectable_cities`,
        type: 'GET',
        dataType: 'json',
        data: {
          prefecture_id: prefectureId,
        }
      }).then(function(res) {
        const cityIdsAndNames = res.city_ids_and_names;
        const $citySelect = findCitySelect($prefectureSelect);
        const cityId = $citySelect.val();

        emptyCitySelect($citySelect);
        $.each(cityIdsAndNames, function() {
          const option = $('<option>').val(this.id).text(this.name);
          $citySelect.append(option);
          // editのロード時に既に市区町村が保存されている場合に選択されている状態にするため
          if(this.id === Number(cityId)) {
            // @see https://qiita.com/xylitol45@github/items/85a92baf2b68c2a1b2d7
            $citySelect.val(cityId).trigger('change');
          }
        });
        $citySelect.prop('disabled', false);
      });
    };

    const initVideoPlaceSelects = function() {
      const $form = $('.js-video-form');
      const $prefectureSelect = $form.find('.js-prefecture-select');
      ajaxFetchSelectableCities($prefectureSelect);
    };

    // 県のselectが選択された時
    $(document).on('change', '.js-prefecture-select', function() {
      ajaxFetchSelectableCities($(this));
    });

    // ページがロードされた時に実行
    initVideoPlaceSelects();
  });
};
