import $ from "jquery";

export default () => {
  $('.js-scene-link').on('click', (event) => {
    const videoPlayer = document.querySelector('.js-player');
    const sceneStartTime = event.target.dataset.sceneTime;

    videoPlayer.currentTime = sceneStartTime;
  });
}
