export default () => {
  const searchSide = document.querySelector('.search-side-panel__wrapper')
  const searchMain = document.querySelector('.search-main__wrapper');
  const narrowSide = document.querySelector('.js-narrow-side-panel__wrapper');

  const closeBtn = document.querySelector('.js-search-side-panel__close-btn');
  const closeBtnSidePanel = document.querySelector('.search-side-panel__header-close-btn');
  const openBtn = document.querySelector('.js-search-side-panel__open-btn');
  const openIcon = document.querySelector('.js-narrow-side-panel__open-icon');

  const closeSidePanel = () => {
    searchSide.style.display = 'none';
    narrowSide.style.display = 'block';
    searchMain.style.marginLeft = '50px';
    closeBtn.style.display = 'none';
    openBtn.style.display = 'inline-flex';
  }
  const openSidePanel = () => {
    narrowSide.style.display = 'none';

    // スマホ等のときはmargin-leftを適用する必要がないため
    // 条件分岐を入れている
    if (window.innerWidth >= 769) {
      searchMain.style.marginLeft = '24%';
    }
    searchSide.style.display = 'block';
    openBtn.style.display = 'none';
    closeBtn.style.display = 'inline-flex';
  }

  closeBtn.addEventListener('click', closeSidePanel)
  closeBtnSidePanel.addEventListener('click', closeSidePanel);
  openBtn.addEventListener('click', openSidePanel)
  openIcon.addEventListener('click', openSidePanel)
}
